import axios from "axios";
import Vue from "vue";
import store from '../store';
import qs from 'qs'
import { logoutRemoveAllCookie } from '@/utils/index'
import Cookies from "js-cookie";
import router from '../router'
import { HttpErrorUpload } from "@/utils/errorUpload"
import $ControllableAPI from './ControllableAPI'

// baseURL
const baseURL = process.env.NODE_ENV === "production" ? "" : "/api";
// 获取Vue下挂载的请求实例
const GetRequestCatch = (key) => Vue.prototype.$AxiosRequestKeys[key]


// --------创建axios实例---------
const $http = axios.create({
    baseURL,
    timeout: 60000 * 5,
    responseType: "json",
    withCredentials: true,
    headers: {
        "TJ-Http-Version": "V2",
        "X-Requested-With": "XMLHttpRequest",
        "version": process.env.VUE_APP_BUILD_VERSION === "v3" ?  3 : 2     // header => version 由于后端服务是一套 为了区分天安与天迹 所以加一个 version
    }
});



// ----------请求拦截器-----------
let loginName = ""  // 当前登录的账号名称，放在请求头里
$http.interceptors.request.use(
    config => {
        // 单点登录页面增加GET请求字段(appid)
        if (store.getters.appidAuthData) {
            let api = config.url.split('?')[0]
            config.url = api + '?' + qs.stringify({ ...qs.parse(config.url.split('?')[1] || ''), ...store.getters.appidAuthData })
        }

        // 请求头携带本人手机号
        if (!loginName) { loginName = Cookies.getJSON(window.$tj_config["cookies"]["USERINFO"])?.login_name || '' }
        if (loginName) { config.headers['uid'] = loginName }

        // 请求key（兼容未通过封装方法进入的方法，重置一个key, 且在此key中添加一些内容）
        let requestKey = config.headers['Tj-Request-Key']
        // 当前接口所在的页面
        let pagePath = router.history.current.path

        // csrfToken
        const csrfToken = (document.querySelector("meta[name='csrf-token']") || {}).content;
        if (csrfToken) config.headers['X-CSRF-TOKEN'] = csrfToken;

        // 增加controllableAPI 中 addHeader 方法添加的 header
        let controllableAPIExp = GetRequestCatch(requestKey)
        config.headers = { ...config.headers, ...controllableAPIExp['$api']['_addHeaders'],pagePath }
        // 记录当前请求内容，兼容未通过封装方法进入的方法，会在key中添加内容
        controllableAPIExp.config = config
        return config;
    },
    error => {  // 请求错误时做些事
        return Promise.reject(error);
    }
)



// ----------响应拦截器-----------
const LoopStatus = [202, 204]; // 需要轮询的接口
const LoopDelayTime = 2500;  // 每次间隔2s
const MaxLoopCount = 1000;   // 最多10000个请求
const TipsLoopCount = 10;   // 修改为多少次才会弹这个弹窗
const LoopTipsDuration = 5;  // 弹窗出现时间， 单位：s
const RequestNoCodeHttpList = ['/js/adcode.js'] // 没有code的接口不上报的接口错误
let isShowLoopTips = false;  // 是否正在弹窗
$http.interceptors.response.use(
    async (response) => {
        const url = response.config.url
        // 请求KEY
        const requestKey = response.config.headers['Tj-Request-Key']
        const vueSavedRequest = GetRequestCatch(requestKey)

        // 数据 - 没有数据的时候
        let data = response.data;
        if (!data) return data;
        const codeStatus = data.code || data.status;
        
        // 不是2xx状态码全部上报
        if (!(/^2[0-9]{2}$/.test(codeStatus)) && !RequestNoCodeHttpList.includes(url)) { HttpErrorUpload(response, vueSavedRequest) }

        // codeStatus为401时重新登录
        if (codeStatus === 401) {
            logoutRemoveAllCookie()  // 清空登录cookie
            sessionStorage.setItem('logoutMessage', '登录已过期，请您重新登录--->error')
            return window.location.href = window.$PublicPath   // 跳转登录    // 跳转登录
        }

        // codeStatus为403时提醒没有权限
        // else if (codeStatus === 403) { // 特殊状态优先判断
        //     Vue.prototype.$message.error(`请求失败：${data.message || data.describe}`);
        // }

        // 如果是轮询中（ loopRequestCount > 0 ）请求，直接返回结果，不经历下面的判断
        else if (vueSavedRequest['status'] === 'looping') {
            vueSavedRequest.$api._getLoopRes&&vueSavedRequest.$api._getLoopRes(data)
            if (vueSavedRequest['loopRequestCount'] === TipsLoopCount) {
                if (!isShowLoopTips) {
                    isShowLoopTips = true
                    Vue.prototype.$notification.info({
                        message: '请求提醒',
                        description: '功能请求耗时较长，请耐心等待，正在努力加载中...',
                        duration: LoopTipsDuration
                    })
                    setTimeout(function () { isShowLoopTips = false }, (LoopTipsDuration + 2) * 1000)
                }
            }

            else if (vueSavedRequest['loopRequestCount'] == MaxLoopCount ) {
                HttpErrorUpload(response, vueSavedRequest, {
                    msg: '接口请求异常 - 接口已超过最大轮询次数限制：' + MaxLoopCount,
                })
                // vueSavedRequest.setStatus('maxloop')
                // return Promise.reject('超过最大轮询次数限制');
            }
            
            // 这个地方的return 是 【需要轮询的】分支内部请求，不影响主请求流程
            return response.data
        }

        // 需要轮询的
        else if (LoopStatus.includes(codeStatus) && vueSavedRequest['isLoop']) {
            data = await new Promise(async (resolve, reject) => {
                // 用户取消检测
                if (vueSavedRequest['status'] === 'canceled') return reject('canceled');
                // 首次开启轮询 - 记录状态等待回调状态
                vueSavedRequest.setStatus('looping') // 设置状态
                // loop 请求【无论成功失败，此次请求都将被抛弃 成功结果将通过首次loop的resolve返回，以此避免形成超长调用链】
                const LoopRequestFun = async () => {
                    // 等待一段时间后再请求
                    await new Promise((reslove) => setTimeout(() => reslove(), LoopDelayTime));
                    // 接口取消检测
                    if (vueSavedRequest['status'] === 'canceled') return reject('canceled');
                    // 轮询次数 + 1
                    vueSavedRequest['loopRequestCount'] = vueSavedRequest['loopRequestCount'] + 1;
                    // action
                    $http(vueSavedRequest['config']).then(res => {
                        // 继续Loop
                        if (LoopStatus.includes(res.code)) {
                            LoopRequestFun()
                        }
                        // 请求成功 (不需要继续轮询， 如果结果不等于200，后续页面内自己判断)，回调
                        else {
                            resolve(res)
                        }
                    }).catch(e => {
                        vueSavedRequest.setStatus('error')
                        reject(e)
                    })
                }
                // 开始loop
                LoopRequestFun()
            })
        }
        
        // 记录请求结束
        vueSavedRequest.setStatus('end')
        // 开始定时清除key
        ActionClearVueRequestKeys()
        return data; // 返回数据
    },

    (error) => {
        console.log('ERROR: 请求失败：', error, error.message)
        if (!error.response) {
            Vue.prototype.$message.error("请求发送失败，请稍后再试");
            return Promise.reject(error);
        }
        
        //  获取当前请求的response
        let response = error.response

        // 普通错误，如果是错误中含有data.location, 则接收并跳转（约定）
        if (error.response && error.response.data && error.response.data.location) {
            window.location = error.response.data.location
            return 
        }

        // 请求错误
        if ([302, 401, 403].includes(error.response.status)) {
            logoutRemoveAllCookie()
            sessionStorage.setItem('logoutMessage', '登录已过期，请您重新登录--->error')
            return window.location.href = window.$PublicPath;
        }

        // 错误通知
        Vue.prototype.$message.error("服务端请求失败，错误码：" + error.response.status);
        // 请求KEY
        const requestKey = response.config.headers['Tj-Request-Key']
        const vueSavedRequest = GetRequestCatch(requestKey)
        
        // 把错误上报给阿里云
        HttpErrorUpload(response, vueSavedRequest)

        // 开始定时清除key
        vueSavedRequest.setStatus('error')
        ActionClearVueRequestKeys()
        
        return {
            code: 418,
            status: 418,
            message: "NO_SHOW_THE_MESSAGE"
        }
    }
);



// 定时删除请求记录
let ClearVueRequestKeysTimer = null;
function ActionClearVueRequestKeys () {
    if (ClearVueRequestKeysTimer) clearInterval(ClearVueRequestKeysTimer)
    ClearVueRequestKeysTimer = setInterval(() => {
        let delKeys = Object.keys(Vue.prototype.$AxiosRequestKeys).filter(key => {
            return ['error', 'canceled', 'maxloop', 'end'].includes(Vue.prototype.$AxiosRequestKeys[key]['status'])
        })
        delKeys.forEach(key => delete Vue.prototype.$AxiosRequestKeys[key])
        // 清除定时
        if (!Object.keys(Vue.prototype.$AxiosRequestKeys).length) return clearInterval(ClearVueRequestKeysTimer)
    }, 4000)
}



// 快速调用办法，无法执行取消等操作
const $post = async (url, data) => await new $ControllableAPI().post(url, data).req()
const $get = async (url, data) => await new $ControllableAPI().get(url, data).req()
const $postForm = async (url, data) => await new $ControllableAPI().postForm(url, data).req()



export {
    axios,
    $http,
    $post,
    $get,
    $postForm,
    baseURL,
    $ControllableAPI
};
