import { $post, $get, $ControllableAPI } from "@/request/index";

export function getVercode (data) {
  return $post("/login/vercode", data);
}

// 退出登录
export function logout (data) {
  return $get("/logout", data);
}

export function login (data) {
  return $post("/login", data);
}

// 天迹登录获取 menu
export function getPermission () {
  return $get("/menu/list");
}
export function getPermissionV2 () {
  return $get("/menu/list");
}

export function getPermissionV2025 () {
  return $get("/menu/new/list");
}

// 天安 v3 menu
export function getPermissionV3 (data) {
  return $get("/menu/list/get", data);
}

// appid鉴权
export function appidIdentify (data) {
  return $get("/identify", data);
}

export function getLogo (url) {
  return $get("/logo", { url });
}

// 判断手机号是否需要登录密码
export function checkPassword (data) {
  return $get("/checkPassword", data);
}

// 人脸校验
export function checkFace(data){
  return $post('/login/verify/checkface',data)
}
// 人脸对比
export function validFace(data){
  return $post('/login/verify/validface',data)
}

// 获取第三方带token的url
export function getTokenJumpUrl(data){
  return $get('/menu/jump/url',data)
}

// 登录二次验证
export function loginTwice(data){
  return $post('/loginTwice',data)
}
// 经侦sso登录
export function jzssoTicket(data){
  return $get('/jzsso/ticket',data)
}
export function jzssoLogin(data){
  return $get('/jzsso/login',data)
}

// 经侦云退出登录
export function jzssoLogout(data){
  return $get('/jzsso/logout')
}