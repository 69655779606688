import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Cookies from 'js-cookie';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { addCsrfMeta } from './utils/document';

// 全局使用到的配置
import dayjs from "dayjs"
window.dayjs = dayjs

// 错误收集上报
import "@/utils/errorUpload.js";
// 图片预览全局配置
import VDPhoto from 'vue-dark-photo'
// 引入vue-dark-photo 样式
import 'vue-dark-photo/lib/vue-dark-photo.css'
Vue.use(VDPhoto)


// 导入全局config
import Config from '@/config/index.config.js'


// 其他window变量配置
window.$PublicPath = process.env.BASE_URL;
window.$isProduction = process.env.NODE_ENV === "production";
window.$tj_config = {
    cookies: Config.cookies
};


// 组件导入都在这个文件夹里
import "./antv/index"


// 自定义错误 用于抛出
window.$customError = function (name, message) {
    this.name = name;
    this.message = message || "Default Message";
    this.stack = new Error().stack;
};
window.$customError.prototype = Object.create(Error.prototype);
window.$customError.prototype.constructor = window.$customError;


// 添加页头csrf
let csrfToken = Cookies.get(window.$tj_config["cookies"]["CSRF_TOKEN"]);
if (csrfToken) addCsrfMeta(csrfToken);


// 设置回到页头
window.$ScrollToTop = (y = 0, x = 0) => window.scrollTo(x, y);

// global style
import "./styles/reset.less";
import "./styles/app.less";
import "@/assets/menu_iconfont/iconfont.css";
import "@/assets/iconfont/iconfont.css";



// 引入主题切换
import "./themes/index"


// 是否为演示账号数据
window.$isDemoUser = (Cookies.getJSON(window.$tj_config["cookies"]["USERINFO"]) || {}).is_demo_user || false;

// 格式化金额
Vue.filter('formatMoney',(val)=>{
    if(val==''||isNaN(val)){
      return val
    }else{
      return Math.floor(val*100)/100
    }
  })
// 外网遮罩
Vue.directive('lineMask', {
  // 指令绑定到元素时调用
  bind(el) {
    if(process.env.VUE_APP_ENV === 'PRIVATE'||window.$isDemoUser)return 
    el.style.position = 'relative';
    // 创建遮罩层
    const mask = document.createElement('div');
    mask.style.position = 'absolute';
    mask.style.top = '0';
    mask.style.left = '0';
    mask.style.width = '100%';
    mask.style.height = '100%';
    mask.style.backgroundColor = 'rgba(255, 255, 255, 0.2)';
    mask.style.backdropFilter = 'blur(6px)'; // 磨砂效果（模糊半径为 10px）
    mask.style.zIndex = '99';
    const tipText = document.createElement('div');
    tipText.style.position = 'absolute';
    tipText.style.top = '50%';
    tipText.style.left = '50%';
    tipText.style.transform = 'translate(-50%, -50%)';
    tipText.style.color = '#888';
    tipText.style.fontSize = '16px';
    tipText.style.fontWeight = 'bold';
    tipText.innerText = '请在内网查看';
    mask.appendChild(tipText);

    // 将遮罩层添加到目标元素上
    el.appendChild(mask);

    // 默认处理点击事件（可选）
    // mask.addEventListener('click', () => {
    //   alert('遮罩层被点击了！');
    // });
  },
  // 指令解绑时调用
  unbind(el) {
    // 清理遮罩层
    const mask = el.querySelector('div');
    if (mask) {
      mask.remove();
    }
  }
});


Vue.prototype.$config = Config;
// 挂载
Vue.config.productionTip = false;
new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");
