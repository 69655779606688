import { getUserInfo } from "../api/index";
import { addCsrfMeta } from "../utils/document";
import Cookies from "js-cookie";
import store from "../store";
import Config from "@/config/index.config.js"

// v2为2，v3为3，不要设置为一样的
// const Version = process.env.VUE_APP_BUILD_VERSION === 'v2' ? '2' : '3'

// 获取个人信息
export const getUserInfoToCookie = function () {
    return new Promise(async (resolve, reject) => {
        let userInfo
        try { userInfo = await getUserInfo() } catch (e) { reject(e) }
        
        // ----- 修改特殊配置 Start ------
        // 此时可以固定，防止与后端返回不同值，造成UI冲突问题（比如： 独立域名登录之前登录过的不同版本
        const Version = userInfo.content.switch_v
        // 不是白名单域名时，也不允许跳转切换版本
        let isHaveCheckVersion = true
        // v3_user -- 代表当前用户是否有版本切换的权限 即天迹切换天安的权限  0代表没有权限 1代表有权限 权限配置在新建用户的时候
        // v3版本中 userInfo.content.v3_user 必定等于1， 否则在登录页登录后就报错了，不会进入这个页面
        if (Version === 2 && !userInfo.content.v3_user) { isHaveCheckVersion = false }
        else if (Version === 3) { isHaveCheckVersion = true }
        // 如果不是白名单域名，则为false
        if (!Config['versionSwitchWhiteHosts'].includes(location.hostname)) { isHaveCheckVersion = false }
        // ----- 修改特殊配置End ------

        // 保存个人信息
        Cookies.set(window.$tj_config["cookies"]["LOGINKEY"], "ok", { path: "/" });
        Cookies.set(window.$tj_config["cookies"]["USERINFO"], userInfo.content, { path: "/" });
        store.commit("setUserInfo", userInfo.content);

        // 获取主题
        Cookies.set(window.$tj_config["cookies"]["STYLE"], userInfo.content.style, { path: "/" });
        store.commit('setThemeType', userInfo.content.style)

        // 因为这个信息有跨域时会被调用，所以需要放在Storage 
        localStorage.setItem(window.$tj_config["cookies"]["ISHAVECHECK"], isHaveCheckVersion ? 'ok' : '')

        // switch_v -- 代表当前用户上一次登录的系统  2代表没有天迹 3代表有天安 用来去追溯定位到用户适用的上一个项目版本
        // localStorage.setItem(window.$tj_config["cookies"]["VERSION"], Version)
        
        // 其他参数设置值
        window.$isDemoUser = userInfo.content.is_demo_user;
        
        // csrf
        Cookies.set(window.$tj_config["cookies"]["CSRF_TOKEN"], userInfo.content.csrf_token, { path: "/" });
        addCsrfMeta(userInfo.content.csrf_token);

        // 返回有效信息
        resolve(userInfo)
    })

}