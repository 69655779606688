import router from "../index";
export default ()=>{
  router.addRoute({
    path:'/2025',
    component:()=>import('@/pages/layout/v2025.vue'),
    children:[
      {
        path:'complete_portrait',
        component:()=>import('@/pages/2025/complete_portrait/index.vue'),
        meta: {
          title: '全息画像',
          parents:[],
      }
      }
    ]
  })
}
