<style lang="less">
#app {
  position: relative;
  height: 100vh;
}
#app-page-main-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px;
}
</style>
<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
zhCN.Empty.description = "无数据"
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
